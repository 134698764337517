import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AppLanguages } from './constants/core';
import uk from './locales/uk.json';
import ru from './locales/ru.json';

export const resources = {
    uk: {
        translation: uk
    },
    ru: {
        translation: ru
    }
} as const;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
            resources,
            detection: {
                order: [ 'localStorage', 'querystring', 'cookie', 'sessionStorage' ],
                lookupLocalStorage: 'language',
                caches: ['localStorage', 'cookie']
            },
            fallbackLng: AppLanguages.uk,
            interpolation: {
                escapeValue: false, 
            }
        });

export default i18n;

const { t: translate, changeLanguage, resolvedLanguage } = i18n;

export { 
    translate,
    changeLanguage,
    resolvedLanguage
};
