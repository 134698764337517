import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ru from 'antd/lib/locale/ru_RU';
import uk from 'antd/lib/locale/uk_UA';
import { useLazyCheckAuthQuery } from './app/endpoints/authApi';
import { useEffect, useMemo } from 'react';
import { localStorageKeys } from './constants/localStorage';
import useCurrentUser from './hooks/useCurrentUser';
import { Throbber } from './components/Throbber';
import { getRoutesByAuth } from './router';
import { App, ConfigProvider } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';
import type { NotificationInstance } from 'antd/es/notification/interface';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { antdTheme } from './constants/core';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/es/locale';

type antdLcl = {
    [key: string]: Locale
};

const antdLocales: antdLcl = {
    ru,
    uk
};

export const AppRouter = () => {
    const [ checkAuth, { isLoading, isUninitialized } ] = useLazyCheckAuthQuery();
    const { i18n: { language } } = useTranslation();
    
    const token = useMemo(() => localStorage.getItem(localStorageKeys.authToken), []);

    useEffect(() =>  { 
        if (token) checkAuth();
    }, [ checkAuth, token ]);

    const user = useCurrentUser();
    const routes = useMemo(() => getRoutesByAuth(user), [ user ]);
    const router = createBrowserRouter(routes);

    return (
        <ConfigProvider 
            locale={antdLocales[language]}
            theme={antdTheme}
        >
            {
                isLoading || (!!token && isUninitialized)
                    ?   <Throbber className='vh100'/>
                    :   <RouterProvider router={router}/>
            }
        </ConfigProvider>
    )       
};

export let message: MessageInstance;
export let notification: NotificationInstance;
export let modal: Omit<ModalStaticFunctions, 'warn'>;

export default function InitGlobal () {
    const staticFunction = App.useApp();
    message = staticFunction.message;
    modal = staticFunction.modal;
    notification = staticFunction.notification;
    return null;
};

