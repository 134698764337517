import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import App from '../App';
import { UserState } from '../types/state';
import { NoPermissions } from '../pages/NoPermissions';
import { UserPermissions } from '../constants/core';

const Auth = lazy(() => import('../pages/Auth').then((module) => ({
    default: module.Auth
})));

const ActivatePassword = lazy(() => import('../pages/ActivatePassword').then((module) => ({
    default: module.ActivatePassword
})));

const Index = lazy(() => import('../pages/Index').then((module) => ({
    default: module.Index
})));

const UsersAndRoles = lazy(() => import('../pages/Team').then((module) => ({
    default: module.Team
})));

const RsList = lazy(() => import('../pages/rs/RsList').then((module) => ({
    default: module.RsList
})));

const RsEditor = lazy(() => import('../pages/rs/RsEditor').then((module) => ({
    default: module.RsEditor
})));

const AddRs = lazy(() => import('../pages/rs/AddRs').then((module) => ({
    default: module.AddRs
})));

const Schedule = lazy(() => import('../pages/rs/Schedule').then((module) => ({
    default: module.Schedule
})));

const baseRoutes = [
    {
        path: '*',
        element: <Navigate to='/'/>
    }
];

const noAuthRoutes = [
    {
        path: 'activation',
        element: <ActivatePassword/>
    },
    {
        path: 'authenticate',
        element: <Auth/>
    },
    {
        path: '/',
        element: <Navigate to='/authenticate'/>
    },
    {
        path: '*',
        element: <Navigate to='/authenticate'/>
    },
];

const privateRoutesAccessList = [
    {
        path: '',
        element: <RsList/>,
        permissions: [ 
            UserPermissions.accessStrings, 
            UserPermissions.manageStrings, 
            UserPermissions.adminStrings, 
            UserPermissions.super 
        ]
    },
    {
        path: 'schedule',
        element: <Schedule/>,
        permissions: [ 
            UserPermissions.accessSchedule, 
            UserPermissions.manageSchedule, 
            UserPermissions.adminSchedule, 
            UserPermissions.super 
        ]
    },
    {
        path: 'new',
        element: <AddRs/>,
        permissions: [ 
            UserPermissions.manageStrings, 
            UserPermissions.adminStrings, 
            UserPermissions.super 
        ]
    },
    {
        path: 'edit/:id',
        element: <RsEditor/>,
        permissions: [ 
            UserPermissions.manageStrings, 
            UserPermissions.adminStrings, 
            UserPermissions.super 
        ]
    },
    {
        path: 'team',
        element: <UsersAndRoles/>,
        permissions: [ UserPermissions.adminUsers, UserPermissions.adminRoles ]
    },
];

const privateRoutes = (user: UserState) => {
    const available = privateRoutesAccessList
        .filter(item => !item.permissions || item.permissions.some(p => user?.permissions?.[p]))
        .map(r => ({
            path: r.path,
            element: r.element
        }));

    if (!!!available.find(r => r.path === '') && available.length) available.push({
        path: '',
        element: available[0].element
    });

    return [
        {
            path: '/',
            element: <Index/>,
            children: available.length ? available : [
                {
                    path: '',
                    element: <NoPermissions/>
                }
            ] 
        },
    ];
}

export const getRoutesByAuth = (user: UserState) => {
    const routes: Array<RouteObject> = [
        {
            path: '/',
            element: <App/>,
            children: [
                ...baseRoutes,
                ...(user.isAuth ? privateRoutes(user) : noAuthRoutes)
            ]
        }
    ];

    return routes;
}