export enum UserPermissions { 
    adminRoles = 'adminRoles',
    adminUsers = 'adminUsers',
    manageStrings = 'manageStrings',
    adminStrings = 'adminStrings',
    accessStrings = 'accessStrings',
    manageSchedule = 'manageSchedule',
    accessSchedule = 'accessSchedule',
    adminSchedule = 'adminSchedule',
    super = 'super'
};

export const antdTheme = {
    token: {
        colorPrimary: '#d16103'
    }
}

export enum AppLanguages {
    ru = 'ru',
    uk = 'uk'
};