import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'antd';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { store } from './app/store';
import InitGlobal, { AppRouter } from './AppRouter';
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App>
            <InitGlobal/>
            <Provider store={store}>
                <AppRouter/>
            </Provider>
        </App>
    </React.StrictMode>
);