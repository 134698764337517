import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { localStorageKeys } from '../../constants/localStorage';
import { AppRootState } from '../store';
import { authApi } from '../endpoints/authApi';
import { UserState } from '../../types/state';
import { AppLanguages } from '../../constants/core';

const getLanguage = () => {
    const savedLanguage = localStorage.getItem(localStorageKeys.language);

    if (!savedLanguage) {
        localStorage.setItem(localStorageKeys.language, AppLanguages.uk);
        
        return AppLanguages.uk;
    }

    return savedLanguage;
}

const initialState: UserState = {
    isAuth: false,
    name: undefined,
    login: undefined,
    id: undefined,
    email: undefined,
    role: undefined,
    language: getLanguage(),
    permissions: {},
};

const setUser = (state: UserState, { payload }: PayloadAction<UserData>, dry?: boolean) => {
    if (!payload.accessToken) return;
        
    const decoded = jwtDecode(payload.accessToken) as tokenPayload;

    if (!decoded) return;
    
    if (!dry) {
        state.name = decoded.name;
        state.login = decoded.login;
        state.id = decoded.id;
        state.email = decoded.email;
        state.role = decoded.role;
        state.permissions = decoded.permissions;
        state.isAuth = true;
    }

    localStorage.setItem(localStorageKeys.authToken, payload.accessToken)
};

const user = createSlice({
    name: 'userState',
    initialState: initialState,
    reducers: {
        logout: state => {
            state = Object.assign(state, initialState);
            localStorage.removeItem(localStorageKeys.authToken);
        },
        refresh: (state, data) => setUser(state, data, state.login === data?.payload.login)
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            authApi.endpoints.login.matchFulfilled,
            setUser
        )
        .addMatcher(
            authApi.endpoints.login.matchRejected,
            state => {
                state = Object.assign(state, initialState)
            }
        )
        .addMatcher(
            authApi.endpoints.logout.matchFulfilled,
            state => user.caseReducers.logout(state)
        )
        .addMatcher(
            authApi.endpoints.checkAuth.matchFulfilled,
            setUser
        )
        .addMatcher(
            authApi.endpoints.checkAuth.matchRejected,
            (state, action) => {   
                if (!action?.meta.condition) {
                    state = Object.assign(state, initialState)
                }
            }
        )
        .addMatcher(
            authApi.endpoints.setPassword.matchFulfilled,
            setUser
        )
    }
})

export const { logout, refresh } = user.actions;

export const selectCurrentUser = (state: AppRootState) => state.userState;

export default user.reducer;